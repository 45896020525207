import React, { Component } from "react";
import { Container } from "react-bootstrap";
import cdkOutputObject from "./cdk-outputs.json";

class Info extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const schedulerEnabled =
      cdkOutputObject.LCchatBackendStack.schedulerEnabled === "true";
    const schedulerDiv = (
      <div>
        <h1 className="top-header"> Info </h1>
        <p className="default-row-spacer">
          Welcome! Long Haul Covid can be a lonely and painful experience. This
          website will help you find other COVID Long Haulers to chat with.{" "}
        </p>
        <p className="default-row-spacer">
          To start, navigate to the "Scheduler" page and create an account. Then
          create an event. Other people on the site will see your event and can
          send a request to connect. You can also browse events that other
          people created and request to join those.{" "}
        </p>
        <p className="default-row-spacer">
          If you want to chat right now, this website has a live chat feature.
          Navigate to the "On-demand chat" section of the site and toggle the
          switch to on. Also, please enable notifications. By doing so, the
          website will notify you once a match is found (feel free to switch to
          other tabs, but don't close this tab).{" "}
        </p>
        <p className="default-row-spacer">
          This website does not moderate or record the video calls.{" "}
        </p>
      </div>
    );

    const noSchedulerDiv = (
      <div>
        <h1 className="top-header"> Info </h1>
        <p className="default-row-spacer">
          Welcome! Long Haul Covid can be a lonely and painful experience. This
          website will help you find other Covid Long Haulers to chat with.{" "}
        </p>
        <p className="default-row-spacer">
          To use this website, navigate to the "On-demand chat" section of the
          site and toggle the switch to on. Also, please enable notifications.
          By doing so, the website will notify you once a match is found (feel
          free to switch to other tabs, but don't close this tab). Once a match
          is found, you will have 1 minute to accept. Once both people accept, a
          video call will be created on the page. You will be able to change
          video call settings (yes/no video, mute/unmute, your name) before
          joining the call.{" "}
        </p>
        <p className="default-row-spacer">
          This website does not moderate or record the video calls.{" "}
        </p>
      </div>
    );

    return (
      <>
        <Container>
          {schedulerEnabled ? schedulerDiv : noSchedulerDiv}
          <div>
            <h1 className="top-header"> Other Links </h1>
            <ul>
              <li className="default-row-spacer">
                <p>
                  Find useful doctors curated by other
                  long-haulers:{" "} 
                  <a href="https://longcoviddoctors.org/">
                     Long Covid Doctors
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </Container>
      </>
    );
  }
}

export default Info;
